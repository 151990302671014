<template>
	<div class="map-compare-container" ref="map2">
		<feature-info :id="idFeatureInfo" :feature="featureInfo"></feature-info>
		<map-tools id="map-compare-tools" />
		<map-identifier v-if="isActiveTabCompareMaps" :name="$t('panels.comparemapspanel.map2')" :left="'1rem'" :bottom="'1rem'"></map-identifier>
		<map-legend-body id="map-compare-legend-body" :isLegendCompareMap2="true" />
	</div>
</template>

<script>
/** Components */
import MapTools from '@/components/map/sections/map/subcomponents/MapTools';
import MapIdentifier from '@/components/map/sections/map/subcomponents/MapIdentifier';
import MapLegendBody from '@/components/map/sections/map/subcomponents/MapLegendBody';
import FeatureInfo from '@/components/map/sections/map/subcomponents/FeatureInfo';

/** Constants */
import ol2map from './subcomponents/ol2map';
import { OSM } from 'ol/source.js';
import TileLayer from 'ol/layer/Tile';
//import { BASEMAP_ID } from '@/components/map/sections/map/subcomponents/mapVariables';

/** WMTS */
import VectorSource from 'ol/source/Vector';

/** 3rd-party */
import 'ol/ol.css';
import Attribution from 'ol/control/Attribution';
import { Vector as VectorLayer } from 'ol/layer';
import Overlay from 'ol/Overlay';
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';
import BaseLayersMixin from '@/mixins/BaseLayersMixin';
const attribution = new Attribution({
	collapsible: false
});

export default {
	name: 'MapComparePanelMap',
	mixins: [BaseLayersMixin],
	components: {
		MapTools,
		MapIdentifier,
		MapLegendBody,
		FeatureInfo
	},
	props: {},
	data() {
		return {
			idFeatureInfo: 'feature-info-compare-map',
			featureInfo: null
		};
	},
	computed: {
		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		}
	},
	watch: {
		'$store.state.map.mapLoadedLayersFinish': {
			handler() {
				if (this.$store.state.map.mapLoadedLayersFinish) {
					//console.log('== Compare Map start ==');
					setTimeout(this.duplicateMapIfEmpty, 2000);
					//this.$store.commit('setMapLoadedLayersFinish', false);
				}
			},
			deep: true
		},
		isActiveTabCompareMaps(val) {
			console.log('isActiveTabCompareMaps', val);
			/* if (val) {
				this.duplicateMapIfEmpty();
			} */
		}
	},
	beforeCreate() {},
	created() {
		this.$puiEvents.$on('map:updated', () => {
			setTimeout(() => {
				this.updateCompareMapLayers();
			}, 1500);
		});
	},
	destroyed() {
		this.$puiEvents.$off('map:updated');
	},
	mounted() {
		console.log('MapComparePanelMap mounted', this.isActiveTabCompareMaps, this.$store.state.map.mapLoadedLayersFinish);
	},
	updated() {},
	beforeDestroy() {},
	methods: {
		getMapLayers() {
			return ol2map
				.getMapInstance()
				.getLayers()
				.getArray()
				.filter((layer) => !(layer instanceof TileLayer && layer.getSource() instanceof OSM))
				.map((layer) => {
					let clonedLayer;
					/*
				if (layer.getSource() instanceof ImageWMS || layer.getSource() instanceof TileWMS) {
					const source = layer.getSource();
					const clonedSource = new (source.constructor)({
						url: source.getUrls()[0],
						params: source.getParams()
					});
					clonedLayer = new TileLayer({
						source: clonedSource,
						visible: layer.getVisible(),
						opacity: layer.getOpacity(),
						zIndex: layer.getZIndex()
					});
				} else */
					if (layer.getSource() instanceof VectorSource) {
						const source = layer.getSource();
						const clonedSource = new VectorSource({
							features: source.getFeatures(),
							format: source.getFormat(),
							url: source.getUrl()
						});
						clonedLayer = new VectorLayer({
							source: clonedSource,
							style: layer.getStyle(),
							visible: layer.getVisible(),
							opacity: layer.getOpacity(),
							zIndex: layer.getZIndex()
						});
					} else {
						clonedLayer = null;
					}
					return clonedLayer;
				})
				.filter((layer) => layer !== null);
		},
		updateCompareMapLayers() {
			const newLayers = this.getMapLayers();
			ol2map
				.getMapCompareInstance()
				.getLayers()
				.forEach((layer) => {
					if (layer instanceof VectorLayer) {
						ol2map.getMapCompareInstance().removeLayer(layer);
					}
				});
			newLayers.forEach((layer) => {
				//ol2map.getMapCompareInstance().addLayer(layer);
				ol2map.getMapCompareInstance().getLayers().insertAt(1, layer);
			});
		},
		duplicateMapIfEmpty() {
			/* const osmLayer = new TileLayer({
				id: BASEMAP_ID,
				source: new OSM()
			}); */
			const esriLayer = this.baseLayersControl[1].layer;

			const clonedLayers = this.getMapLayers();

			//clonedLayers.unshift(osmLayer);
			clonedLayers.unshift(esriLayer);

			this.overlay = new Overlay({
				zIndex: 100,
				element: document.getElementById('feature-popup-compare-map'),
				autoPan: true,
				autoPanAnimation: {
					duration: 250
				}
			});

			this.overlayInfo = new Overlay({
				zIndex: 80,
				element: document.getElementById('feature-info-compare-map'),
				autoPan: true,
				autoPanAnimation: {
					duration: 250
				}
			});

			ol2map.initMapCompare({
				controls: [attribution],
				target: this.$refs.map2,
				layers: clonedLayers,
				overlays: [this.overlay, this.overlayInfo],
				view: ol2map.getMapInstance().getView()
			});

			this.$nextTick(() => {
				this.registerMapEvents();
			});
		},
		registerMapEvents() {
			var self = this;
			const mapCanvas = this.$refs.map2;
			//console.log("mapCanvasCompare",mapCanvas)
			ol2map.getMapCompareInstance().once('postrender', function () {
				self.$store.commit('setMapCompareLoaded', true);
			});

			ol2map.getMapCompareInstance().on('moveend', function () {
				self.$store.commit('setMapZoom', ol2map.getZoomCompareMap());
				self.$store.commit('setMapBbox', ol2map.getMapExtent()); //Initial map area in OpenLayers
			});
			ol2map.getMapCompareInstance().on(
				'pointermove',
				(evt) => {
					const hit = ol2map.getMapCompareInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
						return feature;
					});
					//console.log('hit: ',hit);
					if (hit && Object.hasOwn(hit.getProperties(), 'code')) {
						mapCanvas.style.cursor = 'pointer';
						this.featureInfo = hit.getProperties();
						//console.log('this.featureInfo.id', this.featureInfo.id);

						if (this.featureInfo.id == 'MeasureTool') {
							//console.log('MeasureTool');
							return;
						}
						//console.log('this.featureInfo.id2', this.featureInfo.id);
						this.overlayInfo.setPosition(evt.coordinate);

						//console.log('debug feature info CompareMap', this.featureInfo, this.featureInfo == hit.getProperties());
					} else {
						mapCanvas.style.cursor = '';
						this.featureInfo = null;
						this.overlayInfo.setPosition(undefined);
					}
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);
		}
	}
};
</script>

<style scoped lang="postcss">
.map-compare-container {
	flex: 1;
	height: 100%;
	position: relative;
	margin-left: -20px;

	//margin-left: 1%;
	//height: 100%;
	//position: relative;
}

.ol-attribution {
	left: 0px;
}

/*nuevo measurement*/
.ol-tooltip {
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	color: white;
	padding: 4px 8px;
	opacity: 0.7;
	white-space: nowrap;
	font-size: 12px;
	cursor: default;
	user-select: none;
}

.ol-tooltip-measure {
	opacity: 1;
	font-weight: bold;
}

.ol-tooltip-static {
	background-color: #ffcc33;
	color: black;
	border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
	border-top: 6px solid rgba(0, 0, 0, 0.5);
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
	content: '';
	position: absolute;
	bottom: -6px;
	margin-left: -7px;
	left: 50%;
}

.ol-tooltip-static:before {
	border-top-color: #ffcc33;
}

.feature-btn {
	background-color: var(--azul-primary-100) !important;
	min-width: 32px !important;
	/*padding: 0px !important;*/
}

.right-menu-item {
	display: inline-block;
	padding: 0 8px;
	height: 100%;
	font-size: 18px;
	color: #5a5e66;
	vertical-align: text-bottom;

	&.hover-effect {
		cursor: pointer;
		transition: background 0.3s;

		&:hover {
			background: rgba(0, 0, 0, 0.025);
		}
	}
}
</style>
