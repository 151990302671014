<template>
	<div class="map-container" id="map-canvas" ref="map" :class="isActiveTabCompareMaps ? 'mr-6' : 'mr-0'">
		<div v-if="loadingAnimation" class="d-flex justify-center align-center" style="height: 100vh">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<feature-info :id="idFeatureInfo" :feature="featureInfo"></feature-info>
		<feature-popup :id="idFeaturePopup" :feature="featurePopup" :featureCoordinates="featureCoordinates"> </feature-popup>

		<map-update-control id="map-update-control" />

		<map-tools v-show="!isActiveTabCompareMaps" id="map-tools" />
		<!--<map-zoom-reset id="map-zoom-reset" />-->
		<map-identifier v-if="isActiveTabCompareMaps" :name="$t('panels.comparemapspanel.map1')" :left="'1.5rem'" :bottom="'6.5rem'"></map-identifier>
		<map-base-layer-control id="map-base-layer-control" />
		<map-time-clock id="map-time-clock" />
		<map-legend-body id="map-legend-body" />
		<!-- TODO: Show only for ADMIN_SP -->
		<weather-dialog v-if="!isSuperAdmin"></weather-dialog>
		<feature-card-item :feature="featureClick" :type="featureType" :displayMode="displayMode" v-if="featureClick" v-show="displayMode === 'card'">
			<template #action-button>
				<div class="d-flex flex-nowrap justify-end align-end mt-2">
					<div v-if="notificationsByType" class="d-flex align-center mb-1 pr-2">
						<v-tooltip
							top
							transition="none"
							:color="notificationType.severitycolor + 'd9'"
							v-for="(notificationType, i) in notificationsByType"
							:key="i"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-chip color="white" class="px-2 mr-1" link pill small v-bind="attrs" v-on="on">
									<v-img :src="notificationType.urlbase + notificationType.iconroute" height="24" aspect-ratio="1" contain />
									<span class="ps-2 fs-regular fw-500">{{ notificationType.notifications.length }}</span></v-chip
								>
							</template>
							<span class="fs-regular">{{ notificationType.severityname }}</span>
						</v-tooltip>
					</div>
					<!-- <v-btn @click="toggleScreenFull" depressed small :title="displayModeTitle" class="feature-btn">
						<v-icon class="fak fa-line-open"></v-icon>
					</v-btn> -->
					<v-btn @click="toggleDisplayMode" width="32" height="32" depressed small :title="displayModeTitle" class="feature-btn pa-0">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 30 30">
							<title>{{ $t('common.popOut') }}</title>
							<path fill="#fff" d="M19,17.59L17.59,19L7,8.41V15H5V5H15V7H8.41L19,17.59Z" />
						</svg>
					</v-btn>
					<!-- <v-btn @click="toggleDisplayMode" depressed small :title="displayModeTitle" class="feature-btn">
						<v-icon class="fak fa-line-open" :title="$t('common.popOut')" style="font-size: 0.8rem !important"></v-icon>
					</v-btn> -->

					<v-btn
						v-if="displayMode === 'card'"
						width="32"
						height="32"
						@click="toggleBottomActions"
						class="ml-2 feature-btn color-white pa-0"
						depressed
						small
					>
						<v-icon :class="actionsToggled ? 'fak fa-solid-up' : 'fak fa-solid-down'" style="font-size: 0.8rem !important"></v-icon>
					</v-btn>

					<v-btn width="32" height="32" class="ml-2 feature-btn color-white pa-0" small depressed @click="closeFeature">
						<v-icon class="fak fa-solid-close" style="font-size: 0.8rem !important"></v-icon>
					</v-btn>
				</div>
			</template>
		</feature-card-item>
		<feature-dialog-item
			:feature="featureClick"
			:type="featureType"
			:displayMode="displayMode"
			v-if="featureClick"
			v-show="displayMode === 'dialog'"
		>
			<template #action-button>
				<div class="d-flex flex-nowrap justify-end align-end mt-1">
					<div v-if="notificationsByType" class="d-flex align-center">
						<v-tooltip
							top
							transition="none"
							:color="notificationType.severitycolor + 'd9'"
							v-for="(notificationType, i) in notificationsByType"
							:key="i"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-chip color="white" class="px-2 mr-1" link pill small v-bind="attrs" v-on="on">
									<v-img :src="notificationType.urlbase + notificationType.iconroute" height="24" aspect-ratio="1" contain />
									<span class="ps-2 fs-regular fw-500">{{ notificationType.notifications.length }}</span></v-chip
								>
							</template>
							<span class="fs-regular">{{ notificationType.severityname }}</span>
						</v-tooltip>
					</div>
					<v-btn @click="toggleDisplayMode" depressed small :title="displayModeTitle" class="feature-btn pa-0">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 30 30">
							<title>{{ $t('common.popIn') }}</title>
							<path fill="#fff" d="M5,6.41L6.41,5L17,15.59V9H19V19H9V17H15.59L5,6.41Z" />
						</svg>
					</v-btn>
					<v-btn depressed small class="feature-btn pa-0 ml-2" @click="requestFullscreen">
						<v-icon class="color-white" :class="!fullscreenDialog ? 'fak fa-line-zoom-framing' : 'fas fa-compress'" size="16"></v-icon>
					</v-btn>
					<!-- <v-btn @click="toggleDisplayMode" depressed small :title="displayModeTitle" class="feature-btn">
						<v-icon class="fak fa-line-open" style="font-size: 0.8rem !important"></v-icon>
					</v-btn> -->
					<v-btn style="max-width: 32px; min-width: 32px" class="ml-2 feature-btn color-white pa-0" small depressed @click="closeFeature">
						<v-icon class="fak fa-solid-close" style="font-size: 0.8rem !important"></v-icon>
					</v-btn>
				</div>
			</template>
		</feature-dialog-item>
	</div>
</template>

<script>
/** Components */
//import MapZoomsControl from '@/components/map/sections/map/subcomponents/MapZoomsControlMap';
import MapTools from '@/components/map/sections/map/subcomponents/MapTools';
//import MapZoomReset from '@/components/map/sections/map/subcomponents/MapZoomReset';
import MapIdentifier from '@/components/map/sections/map/subcomponents/MapIdentifier';
import MapBaseLayerControl from '@/components/map/sections/map/subcomponents/MapBaseLayerControl';

import MapLegendBody from '@/components/map/sections/map/subcomponents/MapLegendBody';
import MapUpdateControl from '@/components/map/sections/map/subcomponents/MapUpdateControl';
import FeaturePopup from '@/components/map/sections/map/subcomponents/FeaturePopup';
import FeatureInfo from '@/components/map/sections/map/subcomponents/FeatureInfo';
import FeatureCardItem from '@/components/map/sections/map/subcomponents/FeatureCardItem';
import FeatureDialogItem from '@/components/map/sections/map/subcomponents/FeatureDialogItem';
import WeatherDialog from './subcomponents/weather/WeatherDialog.vue';
import MapTimeClock from '@/components/map/sections/map/subcomponents/MapTimeClock.vue';
//import Screenfull from '@/components/ui/TestFullScreen';
import ReloadStoreMixin from '@/mixins/ReloadStoreMixin';

/** Constants */
import { CENTER, INITIAL_ZOOM } from './subcomponents/mapVariables';
import ol2map from './subcomponents/ol2map';

/** 3rd-party */
import 'ol/ol.css';
import View from 'ol/View';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent';
import Attribution from 'ol/control/Attribution';
import { Vector as VectorLayer } from 'ol/layer';
import Overlay from 'ol/Overlay';

import { isSuperAdmin, search, obtainIndexName, /* getStringFeatureImage, */ processDocumentValidations } from '@/api/common';
import { CARD_HEIGHT, CARD_CONTAINER_HEIGHT, COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';

// eslint-disable-next-line no-unused-vars
import { query_Document, query_LastDataDataByParameterArray } from '@/utils/queries';
import { lastData } from '@/api/databases_API';
import constants from '@/utils/constants';

const attribution = new Attribution({
	collapsible: false
});

export default {
	name: 'MapPanelMap',
	mixins: [ReloadStoreMixin],
	components: {
		//MapZoomsControl,
		MapTools,
		MapIdentifier,
		//MapZoomReset,
		MapLegendBody,
		MapUpdateControl,
		MapBaseLayerControl,
		FeaturePopup,
		FeatureInfo,
		FeatureCardItem,
		FeatureDialogItem,
		MapTimeClock,
		WeatherDialog
		/* Screenfull */
	},

	props: {},

	data() {
		return {
			idFeatureInfo: 'feature-info',
			idFeaturePopup: 'feature-popup',
			mapView: null,
			featurePopup: null,
			featureInfo: null,
			featureCoordinates: null,
			actionsToggled: false,
			loadingAnimation: true,
			featureDialogId: 'feature-dialog-element',
			/* displayMode: 'card' */
			notificationsByType: null,
			primaryColor: this.$store.state.global.primaryColor,
			isSuperAdmin: false,
			reloadUser: false
		};
	},

	computed: {
		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		},
		isActiveFeaturePopupClick() {
			return this.$store.getters.getFeaturePopupClick;
		},
		activePanel() {
			return this.$store.getters.activePanel;
		},
		settings() {
			return this.$store.getters.layers;
		},
		mapLoaded() {
			return this.$store.state.map.mapLoaded;
		},
		featureClick: {
			get() {
				return this.$store.getters.getFeatureClick;
			},
			set(value) {
				this.$store.commit('setFeatureClick', value);
			}
		},
		featurePopupClick: {
			get() {
				return this.$store.getters.getFeaturePopupClick;
			},
			set(value) {
				this.$store.commit('setFeaturePopupClick', value);
			}
		},
		featureType: {
			get() {
				return this.$store.getters.getFeatureType;
			},
			set(value) {
				this.$store.commit('setFeatureType', value);
			}
		},
		popupElasticData: {
			get() {
				return this.$store.getters.getPopupElasticData;
			},
			set(value) {
				this.$store.commit('setPopupElasticData', value);
			}
		},
		loadingPopup: {
			get() {
				return this.$store.getters.getLoadingPopup;
			},
			set(value) {
				this.$store.commit('setLoadingPopup', value);
			}
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		},
		displayModeTitle() {
			return this.displayMode == 'card' ? this.$t('common.popOut') : this.$t('common.popIn');
		},
		featureNotifications() {
			if (this.featureClick && this.$store.getters.getNotifications) {
				return this.$store.getters.getNotifications.filter(
					(notification) => notification.pmdatasourceinstanceid == this.featureClick.pmdatasourceinstanceid
				);
			} else {
				return false;
			}
		},
		fullscreenDialog: {
			get() {
				return this.$store.getters.getFullscreenDialog;
			},
			set(value) {
				this.$store.commit('setFullscreenDialog', value);
			}
		}
	},
	watch: {
		fullscreenDialog(val, prev) {
			console.log('fullscreenDialog watcher', prev, val);
		},
		activePanel: function () {
			if (this.activePanel === COMPAREMAPS_PANEL_ID) {
				ol2map.getMapInstance().un('click', this.addClickEventToMapWithPopup);
			} else {
				ol2map.getMapInstance().on('click', this.addClickEventToMapWithPopup);
			}
		},
		featureNotifications: {
			handler(newValue) {
				//console.log('debug feature notifications', oldValue, newValue);
				if (newValue) this.notificationsByType = this.groupNotificationsByType(this.featureNotifications);
			},
			deep: true
		},
		'$store.state.mappanel.featureCard.featurePopupClick': {
			handler() {
				if (!this.$store.state.mappanel.featureCard.featurePopupClick) {
					this.overlay.setPosition(undefined);
					this.featurePopup = null;
				}
			},
			deep: true
		},
		'$store.state.mappanel.deviceZoom': {
			handler() {
				if (this.$store.state.mappanel.deviceZoom) {
					this.$store.commit('setFeaturePopupClick', false);
					let coordinates = [];
					if (this.$store.state.mappanel.deviceZoom.ismobile == 1) {
						Object.keys(this.$store.getters.layers).forEach((layerId) => {
							if (coordinates.length > 0) return;
							coordinates = ol2map.getMobileFeatureCoordinates(layerId, this.$store.state.mappanel.deviceZoom.identifier);
						});
					} else {
						coordinates = olProj.fromLonLat([
							this.$store.state.mappanel.deviceZoom.longitude,
							this.$store.state.mappanel.deviceZoom.latitude
						]);
					}

					let mapView = ol2map.getMapInstance().getView();
					mapView.setCenter(coordinates);
					mapView.setZoom(16);
					ol2map.getMapInstance().updateSize();

					// Esperar 0.1 segundo antes de ejecutar la siguiente parte del código
					setTimeout(() => {
						console.log('setTimeout', ol2map.getMapInstance().getPixelFromCoordinate(coordinates));
						// Simulamos un click en el mapa
						const evt = {
							pixel: ol2map.getMapInstance().getPixelFromCoordinate(coordinates),
							coordinate: coordinates
						};
						this.addClickEventToMapWithPopup(evt);
						this.$store.commit('setDeviceZoom', null);
					}, 100);
				}
			},
			deep: true
		}
	},
	beforeCreate() {},
	created() {
		//this.getParametersData();
		if (this.reloadUser) this.storeUserProperties();
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		//console.log('info: mappanelmap mounted ', this.mapLoaded);
		// this is where we create the OpenLayers map
		this.$nextTick(() => {
			this.resolveMap();
			this.registerMapEvents();
		});
		document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
		document.addEventListener('keyup', this.toggleEscape);
		this.storeAqiDescriptions();
	},
	updated() {
		//console.log('info: mappanelmap updated ', this.mapLoaded);
	},
	beforeDestroy() {
		//console.log('info: destroy mappanelmap', this.mapLoaded);
		//this.$store.commit('setMapLoaded', false);
		this.closeFeature();
		document.removeEventListener('keyup', this.toggleEscape);
	},

	methods: {
		toggleBottomActions() {
			this.actionsToggled = !this.actionsToggled;
			this.setMapControlClassesForHeight();
		},
		setMapControlClassesForHeight() {
			//console.warn(document.getElementsByClassName('card-content-container')[0]);
			if (!this.actionsToggled) {
				document.getElementsByClassName('card-content-container')[0].style.display = 'block';
				document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT + 'px');
			} else {
				document.getElementsByClassName('card-content-container')[0].style.display = 'none';
				document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT - CARD_CONTAINER_HEIGHT + 'px');
			}
		},
		/* calculateCardComponentHeight() {
			this.bottomActionsComponent.style.height = window.innerHeight / 2 - 100 + 'px';
		}, */
		sleep(milliseconds) {
			var start = new Date().getTime();
			for (var i = 0; i < 1e7; i++) {
				if (new Date().getTime() - start > milliseconds) {
					break;
				}
			}
		},

		resolveMap() {
			this.loadingAnimation = false;
			console.log('debug resolveMap mapp', this.$refs.map, this.loadingAnimation);
			const mapCanvas = this.$refs.map;
			this.overlay = new Overlay({
				zIndex: 100, // Numero alto para que siempre este por encima
				element: document.getElementById('feature-popup'),
				autoPan: true,
				autoPanAnimation: {
					duration: 250
				}
			});

			this.overlayInfo = new Overlay({
				zIndex: 80, // Numero alto para que siempre este por encima
				element: document.getElementById('feature-info'),
				autoPan: true,
				autoPanAnimation: {
					duration: 250
				}
			});

			/*var polygon = [
				[this.userProperties.nelat, this.userProperties.nelon],
				[this.userProperties.swlat, this.userProperties.nelon],
				[this.userProperties.swlat, this.userProperties.swlon],
				[this.userProperties.nelat, this.userProperties.swlon]
			];*/
			var polygon = [
				[this.userProperties.nelon, this.userProperties.nelat],
				[this.userProperties.nelon, this.userProperties.swlat],
				[this.userProperties.swlon, this.userProperties.swlat],
				[this.userProperties.swlon, this.userProperties.nelat]
			];
			console.log('debug polygon', polygon);
			this.mapView = new View({
				zoom: INITIAL_ZOOM,
				minZoom: 3,
				maxZoom: 20,
				center: CENTER,
				constrainResolution: true
			});

			ol2map.init({
				// the map will be created using the 'map' ref
				controls: [attribution],
				target: mapCanvas,
				layers: [],
				overlays: [this.overlay, this.overlayInfo],
				// the map view will initially show the whole world
				view: this.mapView
			});

			if (!isSuperAdmin(this.session.profiles[0])) {
				ol2map.zoomToExtent(olProj.transformExtent(new olExtent.boundingExtent(polygon), 'EPSG:4326', 'EPSG:3857'));
			}
			this.$store.commit('setCenterCoordinates', ol2map.getCenterCoordinates());
			console.log('zoom debug', this.$store.getters.zoom, ol2map.getZoom(), this.$store.state.mappanel.originalZoom);
			this.$store.state.mappanel.originalZoom = ol2map.getZoom();
		},
		/**
		 * Map events
		 */
		addClickEventToMapWithPopup(evt) {
			//console.log("evt", evt)

			this.featurePopupClick = true;
			const hit = ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
				return feature;
			});
			if (hit) {
				if (!this.featurePopup || this.featurePopup.code != hit.getProperties().code) {
					this.featurePopup = hit.getProperties();
					if (Object.hasOwn(this.featurePopup, 'code')) {
						this.overlay.setPosition(evt.coordinate);
						this.featureCoordinates = evt.coordinate;
						if (this.featurePopup.code !== hit.getProperties().code) {
							this.popupElasticData = null;
						}
						if (Object.hasOwn(this.featurePopup, 'originalelasticid')) {
							this.$store.commit('setFeatureType', constants.FEATURE_TYPE_NOTIFICATION);
							this.obtainNotificationData(this.featurePopup.elasticindex, this.featurePopup.originalelasticid);
						} else {
							this.$store.commit('setFeatureType', constants.FEATURE_TYPE_DEVICE);
							this.obtainElasticLastData(this.featurePopup.code);
						}
					}
				}
			} else {
				//this.overlay.setPosition(undefined);
				//this.featurePopup = null;
				this.featurePopupClick = false;
			}
		},

		registerMapEvents() {
			var self = this;
			const mapCanvas = this.$refs.map;
			ol2map.getMapInstance().once('postrender', function () {
				self.$store.commit('setMapLoaded', true);
			});

			ol2map.getMapInstance().on('moveend', function () {
				self.$store.commit('setMapZoom', ol2map.getZoom());
				self.$store.commit('setMapBbox', ol2map.getMapExtent()); //Initial map area in OpenLayers
			});

			ol2map.getMapInstance().on('click', this.addClickEventToMapWithPopup);

			ol2map.getMapInstance().on(
				'pointermove',
				(evt) => {
					const hit = ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
						return feature;
					});
					//console.log('hit: ',hit);
					if (hit && Object.hasOwn(hit.getProperties(), 'code')) {
						mapCanvas.style.cursor = 'pointer';
						this.featureInfo = hit.getProperties();
						//console.log('this.featureInfo', this.featureInfo);

						if (this.featureInfo.id == 'MeasureTool') {
							//console.log('MeasureTool');
							return;
						}
						this.overlayInfo.setPosition(evt.coordinate);

						//console.log('debug feature info Map', this.featureInfo, this.featureInfo == hit.getProperties());
					} else {
						mapCanvas.style.cursor = '';
						this.featureInfo = null;
						this.overlayInfo.setPosition(undefined);
					}
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);
		},
		closeFeature() {
			this.featureClick = null;
			document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
			document.documentElement.style.setProperty('--container-height-dialog', '70vh');
			this.fullscreenDialog = false;
			this.displayMode = 'card';
			this.$store.commit('cleanFormHistoric');
			this.$store.commit('cleanFormAdvancedChart');
			this.$store.commit('cleanFormCompareAqi');
			this.$store.commit('cleanCompareAqiElasticData');
		},

		async getParametersData() {
			var rulesFilter = [
				{ field: 'pmdatasourcetypeid', op: 'eq', data: this.featurePopup.pmdatasourcetypeid },
				{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
				{ field: 'lang', op: 'eq', data: this.userProperties.language },
				{ field: 'disabled', op: 'eq', data: 0 }
				//{ field: 'visible', op: 'eq', data: 1 }
			];

			if (isSuperAdmin(this.session.profiles[0])) {
				rulesFilter = [
					{ field: 'pmdatasourcetypeid', op: 'eq', data: this.featurePopup.pmdatasourcetypeid },
					{ field: 'pmorganizationid', op: 'eq', data: this.featurePopup.pmorganizationid },
					{ field: 'lang', op: 'eq', data: this.userProperties.language },
					{ field: 'disabled', op: 'eq', data: 0 }
					//{ field: 'visible', op: 'eq', data: 1 }
				];
			}

			let body = {
				model: 'vluppmparameterdatasourcetype',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesFilter
				},
				rows: 999
			};
			let { data } = await search(body);
			//console.log('After get parameters');
			if (data) {
				//console.log(data.data);
				this.$store.commit('setParameters', data.data);
			}
		},

		async obtainElasticLastData(stationCode) {
			this.popupElasticData = null;
			this.loadingPopup = true;
			if (
				this.featurePopup.spatialmodel !== constants.SATELLITE_MODEL &&
				this.featurePopup.elasticacronym !== constants.DATASOURCETYPE_SATELLITE
			) {
				const indexName = obtainIndexName(this.featurePopup);

				//const indexName = 'sp_dui_weatherobserved';
				//console.log(indexName);
				//stationCode = 'WEA-1';

				var existsFieldArray = [];
				var visibleParameters = this.$store.getters.getParameters.filter(
					(parameter) =>
						parameter.visible !== 0 &&
						parameter.pmorganizationid == this.featurePopup.pmorganizationid &&
						parameter.pmdatasourcetypeid == this.featurePopup.pmdatasourcetypeid
				);

				visibleParameters.forEach((parameter) => {
					existsFieldArray.push({
						exists: {
							field: parameter.elasticacronym
						}
					});
				});

				//console.log('visibleParameters mapp', visibleParameters);
				//console.log('existsFieldArray', existsFieldArray);

				const query = query_LastDataDataByParameterArray(stationCode, existsFieldArray);
				//const query = duisport_query(stationCode);
				//console.log('QUERY: ', query, 'index name:', indexName);

				const promise = lastData(indexName, query);
				promise.then((elasticData) => {
					console.log('debug elasticData', elasticData);
					let messageData = -1;
					if (typeof elasticData.message != 'string' && (elasticData?.message ?? '').length > 0) {
						messageData = elasticData.message[0]['_source'];
						if (Object.hasOwn(messageData, 'validations') && messageData.validations.length > 0) {
							let parameterValidations = processDocumentValidations(
								messageData.validations,
								this.session,
								this.$store.getters.getErrorCodes
							);
							//console.log('debug popup validations', parameterValidations, parameterValidations.length);
							if (parameterValidations) messageData.validations = parameterValidations;
						}
					}

					this.popupElasticData = messageData;
					this.loadingPopup = false;
					//console.log('debug popup elastic ' + stationCode, messageData, messageData.validations);
				});
			}
		},
		async obtainNotificationData(elasticIndex, originalElasticId) {
			//console.debug('debug obtainNotificationData', elasticIndex, originalElasticId, this.featurePopup);
			this.popupElasticData = null;
			this.loadingPopup = true;

			if (
				this.featurePopup.spatialmodel !== constants.SATELLITE_MODEL &&
				this.featurePopup.elasticacronym !== constants.DATASOURCETYPE_SATELLITE
			) {
				lastData(elasticIndex, query_Document(originalElasticId)).then((elasticData) => {
					//console.log('debug notification data', elasticData, elasticData.message['_source']);
					this.popupElasticData =
						typeof elasticData.message != 'string' && elasticData.message.length > 0 ? elasticData.message[0]['_source'] : -1;
					this.loadingPopup = false;
				});
			}
		},
		toggleDisplayMode() {
			// Toggle between 'dialog' and 'card' display modes
			//console.log('displaymode toggle', this.displayMode);
			this.displayMode = this.displayMode === 'dialog' ? 'card' : 'dialog';
			this.fullscreenDialog = false;
			switch (this.displayMode) {
				case 'card':
					document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT + 'px');
					break;
				case 'dialog':
					document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
					break;
			}
		},
		toggleScreenFull() {
			//console.log('debug togglefullscreen', document.getElementById('feature-card-item'));
			document.getElementById('feature-card-item').requestFullscreen();
		},
		requestFullscreen() {
			console.log('requestFullscreen', this.fullscreenDialog);
			this.fullscreenDialog = !this.fullscreenDialog;
			if (this.fullscreenDialog) {
				document.documentElement.style.setProperty('--container-height-dialog', 'calc(100% - 52px)');
			} else {
				document.documentElement.style.setProperty('--container-height-dialog', '70vh');
			}
		},
		toggleEscape(e) {
			console.log('toggleEscape', e.key, e.key == 'Escape', this.displayMode == 'dialog');
			if (e.key == 'Escape' && this.displayMode == 'dialog') this.toggleDisplayMode();
		},
		groupNotificationsByType(notifications) {
			const object = notifications.reduce((r, { severityname, severitycolor, iconroute, urlbase, ...rest }) => {
				if (!r[severityname])
					r[severityname] = {
						severityname,
						severitycolor,
						iconroute,
						urlbase,
						notifications: [{ severityname, severitycolor, iconroute, urlbase, ...rest }]
					};
				else r[severityname].notifications.push({ severityname, severitycolor, iconroute, urlbase, ...rest });
				return r;
			}, {});
			//console.log('debug: group notif', object);
			return object;
		},
		async storeAqiDescriptions() {
			let body = {
				model: 'pmaqidescription',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: []
				},
				rows: 10
			};
			let { data } = await search(body);
			console.log('After get AQI descriptions', data.data);
			if (data) {
				this.$store.commit('setAqiDescriptions', data.data);
			}
		}
	}
};
</script>

<style scoped lang="postcss">
.map-container {
	flex: 1 1 100%;
	height: 100%;
	position: relative;
	margin-left: -42px !important;
}

.ol-attribution {
	left: 0px;
}

/*nuevo measurement*/
.ol-tooltip {
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	color: white;
	padding: 4px 8px;
	opacity: 0.7;
	white-space: nowrap;
	font-size: 12px;
	cursor: default;
	user-select: none;
}

.ol-tooltip-measure {
	opacity: 1;
	font-weight: bold;
}

.ol-tooltip-static {
	background-color: #ffcc33;
	color: black;
	border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
	border-top: 6px solid rgba(0, 0, 0, 0.5);
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
	content: '';
	position: absolute;
	bottom: -6px;
	margin-left: -7px;
	left: 50%;
}

.ol-tooltip-static:before {
	border-top-color: #ffcc33;
}

.feature-btn {
	background-color: var(--azul-primary-100) !important;
	min-width: 32px !important;
	/*padding: 0px !important;*/
}

.right-menu-item {
	display: inline-block;
	padding: 0 8px;
	height: 100%;
	font-size: 18px;
	color: #5a5e66;
	vertical-align: text-bottom;

	&.hover-effect {
		cursor: pointer;
		transition: background 0.3s;

		&:hover {
			background: rgba(0, 0, 0, 0.025);
		}
	}
}
</style>
