import axios from 'axios';

import Pui9HttpRequests from '@Pui9Requests';
import constants from '@/utils/constants';

// #region pm_actions
// eslint-disable-next-line no-unused-vars
export async function submitAction(action, data, appProperties, userProperties) {
	const maxRetries = action.retrycount || 3;
	const timeoutInMilliseconds = action.timeout > 0 ? action.timeout * 1000 : 30000; // Timeout por defecto de 30s. Pese a ser requerido puede llegarnos un 0 daría problemas
	// Va en milisegundos

	//console.log('Max retries:', maxRetries);

	const actionHeaders = JSON.parse(action.headers); // headers debe ser un objeto JSON
	//console.log('Headers:', actionHeaders);

	const actionRequestBody = JSON.parse(action.requestbody);
	//console.log('Request body mapping:', actionRequestBody);

	const proxyUrl = '/dynamic_proxy'; // URL del proxy definido en vue.config.js

	const actionurl = action.actionurl; // La URL real de destino (de la acción)
	// eslint-disable-next-line no-unused-vars
	const baseUrl = appProperties.urlbase;
	//const actionurl = buildUrl(action,appProperties); // La URL real de destino (de la acción)
	console.log('URL de destino:', actionurl);

	//console.log('Esto es el contenido del formulario:', data);

	// Preparar el cuerpo de la petición basándose en el mapeo definido en action.requestbody
	let requestBody = data;
	if (actionRequestBody) {
		requestBody = prepareRequestBody(data, actionRequestBody);
	}

	//console.log('Request body:', requestBody);

	let isInternalAction = 'true';
	if (action.isexternal) {
		isInternalAction = 'false';
	}

	const token = userProperties.organizationtoken; // Obtén el token del store
	console.log('token', token);

	const config = {
		url: proxyUrl, // Apunto al proxy
		method: action.actionmethod,
		//isexternal : action.isexternal,
		headers: {
			...actionHeaders,
			'target-url': actionurl, // Encabezado para el proxy con la URL de destino
			'is-internal-action': isInternalAction, // Encabezado para el proxy con la indicación de si es una acción interna
			Authorization: `Bearer ${token}`,
			baseUrl: baseUrl
		},
		timeout: timeoutInMilliseconds, // Configurar timeout en milisegundos
		data: requestBody
	};

	// Configurar autenticación
	configureAuthentication(config, action);

	console.log('Config with authentication:', config);

	// Asegurarse de que successCondition es un objeto
	if (typeof action.successcondition === 'string') {
		try {
			action.successcondition = JSON.parse(action.successcondition);
		} catch (error) {
			console.error('Error parsing successCondition:', error);
			return {
				success: false,
				message: 'Invalid success condition format',
				error: error.message
			};
		}
	}

	console.log('Before executing action:', action);

	for (let attempt = 0; attempt < maxRetries; attempt++) {
		try {
			/*console.log('Executing action:', action);
            const response = await axios(config); // Enviar solicitud al proxy

            console.log('Response:', response);
            console.log('Respuesta:', response.data);
            */
			let response;
			let queryString = '';
			// Realizar la solicitud HTTP
			switch (action.actionmethod) {
				case 'POST':
					response = await axios.post(config.url, config.data, { headers: config.headers, timeout: config.timeout });
					break;
				case 'GET':
					queryString = flattenParams(config.data);
					console.log('QueryString:', queryString);
					console.log(`${config.url}?${queryString}`);
					response = await axios.get(`${config.url}?${queryString}`, { headers: config.headers, timeout: config.timeout });
					//response = await axios.get(config.url, { params: config.data, headers: config.headers, timeout: config.timeout });
					break;
				case 'PUT':
					response = await axios.put(config.url, config.data, { headers: config.headers, timeout: config.timeout });
					break;
				case 'DELETE':
					response = await axios.delete(config.url, { data: config.data, headers: config.headers, timeout: config.timeout });
					break;
				default:
					throw new Error(`Unsupported action method: ${action.actionmethod}`);
			}

			console.log('Response:', response);
			console.log('Respuesta:', response.data);

			// Evaluar successcondition
			if (!action.successcondition || Object.keys(action.successcondition).length === 0) {
				return {
					// No se devuelve property success.
					// Esto controla que se visualice el mensaje de notification correspondiente.
					message: 'No success condition provided',
					data: response
				};
			} else if (evaluateSuccessCondition(response, action.successcondition)) {
				return {
					success: true,
					message: 'Action completed successfully',
					data: response
				};
			} else {
				console.warn('Response did not meet success condition');
				return {
					success: false,
					message: 'Response did not meet success condition',
					data: response
				};
			}
		} catch (error) {
			if (attempt === maxRetries - 1) {
				console.error('Error submitting action:', error, error.response, error.response.data);
				let errorDetail;
				if (error.response) {
					errorDetail = error.response.data;
				} else {
					errorDetail = error.message;
				}
				return {
					success: false,
					message: 'Error submitting action',
					error: errorDetail
				};
			}
			console.log(`Retrying... (${attempt + 1}/${maxRetries})`);
		}
	}
}

// Función para evaluar la condición de éxito
function evaluateSuccessCondition(response, successCondition) {
	console.log('Success condition (JSON):', successCondition);

	for (const key in successCondition) {
		console.log('key:', key);
		if (Object.prototype.hasOwnProperty.call(successCondition, key)) {
			console.log('response[key]:', response[key]);
			console.log('successCondition[key]:', successCondition[key]);
			if (response[key] !== successCondition[key]) {
				return false;
			}
		}
	}
	// Si llega hasta aquí o es porque no hay successCondition o porque todas las condiciones se cumplieron
	// Si no hay successCondition consideramos que la respuesta fue exitosa. No podemos evaluarla
	return true;
}

// Función para configurar la autenticación
function configureAuthentication(config, action) {
	console.log(config);
	console.log(action.authtype);
	switch (action.authtype) {
		case 'basic':
			config.headers['Authorization'] = `Basic ${btoa(action.authcredentials)}`;
			break;
		case 'token':
			config.headers['Authorization'] = `Bearer ${action.authcredentials}`;
			break;
		case 'OAuth2':
			config.headers['Authorization'] = `Bearer ${action.authcredentials}`;
			break;
		case 'none':
		default:
			// No se requiere autenticación
			break;
	}
}

function prepareRequestBody(data, requestBodyMapping) {
	const requestBody = {};
	if (typeof requestBodyMapping !== 'object' || requestBodyMapping === null) {
		console.error('Invalid requestBodyMapping:', requestBodyMapping);
		return requestBody;
	}
	for (const key in requestBodyMapping) {
		if (Object.prototype.hasOwnProperty.call(requestBodyMapping, key)) {
			const dataKey = requestBodyMapping[key];
			if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
				requestBody[key] = data[dataKey];
			} else {
				console.warn(`Key ${dataKey} not found in data`);
			}
		}
	}
	return requestBody;
}

// Función para aplanar los parámetros Y convertirlos en una cadena (query string)
function flattenParams(params) {
	return Object.keys(params)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		.join('&');
}

// eslint-disable-next-line no-unused-vars
function buildUrl(action, appProperties) {
	console.log('Estoy en buildUrl');
	console.log(action);
	if (action.isexternal) {
		// Si la acción es externa, ya tiene una URL absoluta
		console.log('Acción externa');
		return action.actionurl;
	} else {
		// Si la acción es interna, concatenamos la URL base del store
		console.log('Acción interna'); // Solo puede ser de Node-RED

		const baseUrl = appProperties.urlbase;
		// Con la regular expressión se elimina la barra final de la URL base y la barra inicial de la acción
		// Evito problemas de doble barra en la URL
		return `${baseUrl.replace(/\/$/, '')}${action.actionurl.replace(/^\//, '')}`;
		//urlbase
	}
}

// #endregion (pm_actions)

// #region pm_actions_log
export async function logAction(url, action, device, actionResponse, actionFormData = null) {
	try {
		const date = new Date();
		const model = formatLogModel(action, device, actionResponse, actionFormData);
		/* const model = {
            pmactionsid: 5,
            pmdatasourceinstanceid: 4,
            pmorganizationid: 2,
            executionresult: 'SUCCESS',
            parameterused: null,
            errormessage: null,
            executionutc: date.toISOString(),
            actiontype: 'MANUAL'
        }; */

		//let res = await insert(body, model);
		console.log('logAction model url', model, url, date.toISOString());
		if (model) {
			Pui9HttpRequests.postRequest(
				url,
				model,
				(response) => {
					console.debug('logAction insert response', response.data);
				},
				(error) => {
					console.debug('logAction insert response', error);
				}
			);
		} else {
			throw new Error('Error formatting action log');
		}
	} catch (e) {
		console.error('logAction error', e);
	}
}

const formatLogModel = (action, device, actionResponse, actionFormData) => {
	try {
		console.log('formatLogModel', action, device, actionResponse);
		const date = new Date();
		let parametersUsed = null;
		if (actionFormData) {
			parametersUsed = JSON.stringify(actionFormData);
		}
		return {
			pmactionsid: action.pmactionsid,
			pmdatasourceinstanceid: device.pmdatasourceinstanceid,
			pmorganizationid: device.pmorganizationid,
			executionresult: actionResponse.executionresult || constants.ACTION_RESULT_COMPLETED,
			parameterused: parametersUsed,
			errormessage: actionResponse.error,
			executionutc: date.toISOString,
			actiontype: 'MANUAL',
			usr: action.user
		};
	} catch (e) {
		console.error('formatLogModel error', e);
		return;
	}
};
// #endregion (pm_actions_log)
