<template>
	<v-data-table
		v-if="notifications"
		:headers="getNoticationHeaders()"
		:items="notifications"
		:single-expand="singleExpand"
		:expanded.sync="expanded"
		item-key="pm_notifications_id"
		show-expand
		:show-select="false"
		:items-per-page="displayLocation == 'card' ? 5 : fullscreenDialog ? 20 : 10"
		class="elevation-1"
		style="overflow-y: scroll !important"
		:loading="loadingAnimation"
		loading-text="Loading..."
		:footer-props="{
			showFirstLastPage: true,
			showCurrentPage: true,
			firstIcon: 'fak fa-line-start',
			lastIcon: 'fak fa-line-final',
			prevIcon: 'fak fa-line-back',
			nextIcon: 'fak fa-line-next',
			itemsPerPageOptions: [5, 10, 20, 50, -1]
		}"
		@click:row="expandRow"
	>
		<template v-slot:item.rule_acronym="{ item }">
			<div>
				<span class="fw-bold" :style="'color:' + item.severity_color" dark>
					{{ getRuleName(item['rule_acronym']) }}
				</span>
				<v-tooltip v-if="item['provider_name']" top transition="none" :color="primaryColor">
					<template v-slot:activator="{ on }">
						<v-icon color="secondary" class="info-tooltip pl-1" size="14" v-on="on">fas fa-info-circle info-tooltip</v-icon>
					</template>
					<div class="d-flex flex-column align-center">
						<span v-if="item['filter_is_query'] == 0" class="fs-regular fw-bold">{{
							getFilterExpression(item['filter'], item['filter_is_query'])
						}}</span>
						<span v-else-if="item.description" class="fs-regular fw-regular">{{ item.description }}</span>
					</div>
				</v-tooltip>
			</div>
		</template>
		<!-- <template v-slot:item.start_date="{ item }">
			<span v-if="item['start_date']" class="fw-regular">{{ getDateFormat(item['start_date']) }}</span>
		</template> -->
		<template v-slot:item.is_average="{ item }">
			<span v-if="item['is_average'] == 1" class="fw-regular">
				{{ $t('general.yes') }}
			</span>
			<span v-else-if="item['is_average'] == 0" class="fw-regular">
				{{ $t('general.no') }}
			</span>
		</template>
		<template v-slot:item.is_mobile="{ item }">
			<span v-if="item['is_mobile'] == 1" class="fw-regular">
				{{ $t('general.yes') }}
			</span>
			<span v-else-if="item['is_mobile'] == 0" class="fw-regular">
				{{ $t('general.no') }}
			</span>
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length" class="px-1">
				<v-data-table
					v-if="elasticDetail[item['pm_notifications_id']] && item['is_average'] == 0"
					:headers="getElasticHeaders(item['pm_notifications_id'])"
					:items="[elasticDetailRounded[item['pm_notifications_id']]]"
					hide-default-footer
					class="elevation-1 my-1"
				></v-data-table>
				<v-data-table
					v-else-if="item['is_average'] == 1"
					:headers="getAverageHeaders()"
					:items="[
						{
							parameter: getPollutantName(item['parameter_acronym']),
							average: item['parameter_value'] + getUnit(item['parameter_acronym']),
							population: item['population'],
							interval: getInterval(item['pm_rules_id'], item['start_date_timestamp']),
							operator: getOperator(item['filter'])
						}
					]"
					hide-default-footer
					class="elevation-1 my-1"
				></v-data-table>
			</td>
		</template>
	</v-data-table>
	<v-data-table v-else :items="[]" :loading="loadingAnimation" loading-text="Loading..." class="elevation-1"></v-data-table>
</template>

<script>
//import { getElasticDocument } from '@/api/databases_API';
import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';
import ViewsMixin from '@/mixins/ViewsMixin';

import { formatTimestamp } from '@/api/common';
//import { getElasticDocument } from '@/api/databases_API';
import { lastData } from '@/api/databases_API';
import { query_Document } from '@/utils/queries';

export default {
	name: 'NotificationActive',
	components: {},
	mixins: [QueriesSQLMixin, ViewsMixin],
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			dialogModel: false,
			expanded: [],
			singleExpand: true,
			notifications: null,
			loadingAnimation: true,
			elasticDetail: {},
			elasticDetailRounded: {},
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		deviceParameters() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		}
	},
	watch: {
		dialog() {
			this.dialogModel = this.dialog;
		}
	},
	created() {},
	mounted() {
		const view = this.formatView(NOTIFICATIONS_LAYER, this.getViewById(NOTIFICATIONS_LAYER));
		this.getActiveNotifications(view, this.deviceObject.pmdatasourceinstanceid, this.deviceObject.pmorganizationid).then((result) => {
			console.log('debug getActiveNotifications', this.deviceObject, result);
			if (result.length > 0) {
				this.notifications = result;
				this.notifications.forEach((notification) => {
					if (Object.hasOwn(notification, 'start_date')) {
						notification['start_date_timestamp'] = notification['start_date'];
						notification['start_date'] = formatTimestamp(parseInt(notification['start_date']), this.userProperties);
					}
					if (notification.is_average == 0) {
						this.getDocument(notification['elastic_index'], notification['original_elastic_id'], notification['pm_notifications_id']);
					}
				});

				this.loadingAnimation = false;
			}
		});
	},
	methods: {
		getNoticationHeaders() {
			console.log('debug: getNoticationHeaders', this.notifications, Object.keys(this.notifications[0]));

			//let notificationKeys = Object.keys(this.notifications[0]);
			let notificationKeys = ['rule_acronym', 'start_date', 'is_average', 'is_mobile' /* , 'provider_name' */];

			const mappedArray = notificationKeys.map((key) => {
				return { text: this.$t('panels.notifications.' + key), value: key };
			});
			console.log('debug: mapped array', notificationKeys, mappedArray);
			mappedArray.push({ text: this.$t('panels.notifications.source'), value: 'data-table-expand' });

			return mappedArray;
		},

		getElasticHeaders(notificationId) {
			var details = [];
			let obj = {};
			console.log('info: Reading promise', this.deviceParameters);
			obj = this.elasticDetail[notificationId];

			this.deviceParameters.forEach((parameter) => {
				if (Object.hasOwn(obj, parameter.elasticacronym)) {
					details.push({
						text: parameter.name,
						value: parameter.elasticacronym
						/* parseInt(obj[parameter.elasticacronym]) == obj[parameter.elasticacronym]
								? parseInt(obj[parameter.elasticacronym])
								: parseFloat(obj[parameter.elasticacronym]).toFixed(2) */
					});
				}
			});

			/* let elasticKeys = Object.keys(this.elasticDetail);

			const mappedArray = elasticKeys.map((key) => {
				return { text: this.$t('panels.notifications.' + key), value: key };
			}); */
			console.log('debug: getElasticHeaders', details);
			//this.getElasticData(notificationId);
			return details;
		},
		getElasticData(notificationId) {
			let elasticKeys = Object.keys(this.elasticDetail);
			//let elasticDetailRounded = {};
			let elasticDetailRounded = {};
			elasticKeys.forEach((key) => {
				console.log('test ', key, isNaN(parseInt(this.elasticDetail[key])));
				if (!isNaN(parseInt(this.elasticDetail[key]))) {
					elasticDetailRounded[key] =
						parseInt(this.elasticDetail[key]) == this.elasticDetail[key]
							? parseInt(this.elasticDetail[key])
							: parseFloat(this.elasticDetail[key]).toFixed(2);
				} else {
					elasticDetailRounded[key] = this.elasticDetail[key];
				}

				/* elasticDetailRounded.push({
					index: key,
					value:
						parseInt(this.elasticDetail[key]) == this.elasticDetail[key]
							? parseInt(this.elasticDetail[key])
							: parseFloat(this.elasticDetail[key]).toFixed(2)
				}); */
			});

			//return elasticDetailRounded;
			this.elasticDetailRounded[notificationId] = elasticDetailRounded;
			console.log('debug: getElasticData', notificationId, this.elasticDetailRounded);
		},

		getViewById(identifier) {
			return this.views[identifier];
		},

		formatView(identifier, view) {
			return `"${view}" AS ${this.preffixes[identifier]}`;
		},

		getDocument(index, documentId, notificationId) {
			console.log(
				'debug document: ',
				index,
				documentId,
				notificationId,
				!!this.elasticDetail,
				Object.hasOwn(this.elasticDetail, notificationId)
			);
			console.log('inside ', notificationId);
			lastData(index, query_Document(documentId)).then(({ message }) => {
				console.log('document then', message);
				if (typeof message !== 'string') {
					this.elasticDetail[notificationId] = message[0]['_source'];
					this.setRoundElasticData(message[0]['_source'], notificationId);
				}
				console.log('debug elastic data then', this.elasticDetail);
			});
		},
		setRoundElasticData(elasticSource, notificationId) {
			let elasticKeys = Object.keys(elasticSource);
			//let elasticDetailRounded = {};
			let elasticDetailRounded = {};
			elasticKeys.forEach((key) => {
				console.log('test ', key, isNaN(parseInt(elasticSource[key])));
				let parameter = this.deviceParameters.find((parameter) => parameter.elasticacronym == key);

				if (!isNaN(parseInt(elasticSource[key]))) {
					elasticDetailRounded[key] =
						parseInt(elasticSource[key]) == elasticSource[key] ? parseInt(elasticSource[key]) : parseFloat(elasticSource[key]).toFixed(2);
					if (parameter) elasticDetailRounded[key] += ' ' + parameter.symbol;
				} else {
					elasticDetailRounded[key] = this.elasticDetail[notificationId][key];
				}

				/* elasticDetailRounded.push({
					index: key,
					value:
						parseInt(this.elasticDetail[key]) == this.elasticDetail[key]
							? parseInt(this.elasticDetail[key])
							: parseFloat(this.elasticDetail[key]).toFixed(2)
				}); */
			});

			//return elasticDetailRounded;
			this.elasticDetailRounded[notificationId] = elasticDetailRounded;
			console.log('debug: getElasticData', elasticSource, notificationId, this.elasticDetailRounded);
		},
		getAverageHeaders() {
			return [
				{ text: this.$t('panels.notifications.parameter'), value: 'parameter' },
				{ text: this.$t('panels.notifications.operator'), value: 'operator' },
				{ text: this.$t('panels.notifications.average'), value: 'average' },
				{ text: this.$t('panels.notifications.population'), value: 'population' },
				{ text: this.$t('panels.notifications.interval'), value: 'interval' }
			];
		},
		getUnit(acronym) {
			let parameter = this.deviceParameters.find((parameter) => parameter.acronym == acronym);
			if (parameter) {
				return ' ' + parameter.symbol;
			}
			return '';
		},
		getPollutantName(acronym) {
			let parameter = this.deviceParameters.find((parameter) => parameter.acronym == acronym);
			if (parameter) {
				return parameter.name;
			}
			return '';
		},
		getInterval(rule_id, start_date) {
			let rule = this.$store.getters.getRules.find((rule) => rule.pmrulesid == rule_id);
			if (rule) {
				return (
					formatTimestamp(parseInt(start_date - rule.windowseconds * 1000), this.userProperties) +
					' - ' +
					formatTimestamp(parseInt(start_date), this.userProperties)
				);
			}
			return 'Not available';
		},
		getDateFormat(startdate) {
			formatTimestamp(parseInt(startdate), this.userProperties);
		},
		getFilterExpression(filter, isQuery) {
			console.log('debug active', filter, isQuery);
			let filterObj = JSON.parse(filter);
			let operator = 'gt';
			let value = '';
			if (filterObj.rules[0].op.split('_').length > 1) operator = filterObj.rules[0].op.split('_')[1];
			if (filterObj.rules[0].data) value = filterObj.rules[0].data;
			return filterObj.rules[0].field + ' ' + this.$t('panels.notifications.' + operator) + ' ' + value;
		},
		getRuleName(ruleAcronym) {
			let rule = this.$store.getters.getRules.find((rule) => rule.acronym == ruleAcronym);
			console.log('dev hlp rules', ruleAcronym, rule);
			if (rule) return rule.name;
			return ruleAcronym;
		},
		getOperator(filter) {
			let filterObj = JSON.parse(filter);
			let operator = filterObj.rules[0].op.split('_')[0];
			let msg = '';
			switch (operator) {
				case 'avg':
					msg = this.$t('panels.notifications.average_operator');
					break;
				default:
					break;
			}
			return msg;
		},
		expandRow(rowData) {
			if (this.expanded[0] == rowData) this.expanded = [];
			else this.expanded = [rowData];
		}
	}
};
</script>

<style lang="postcss">
.v-data-table {
	& span {
		font-size: 1rem;
	}

	& td {
		font-size: 1rem;
	}
}
</style>
