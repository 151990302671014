<template>
	<v-card class="map-control map-control--top-right" color="#fff" :class="{ [`elevation-${expanded ? 0 : 2}`]: true }">
		<div class="d-flex align-center pa-1" @click="expanded = !expanded" style="cursor: pointer">
			<!-- <span v-if="!isActiveTabCompareMaps" class="px-2 fs-regular fw-500">{{ $t('legend.legend') }}</span>
			<span v-if="isActiveTabCompareMaps && !this.isLegendCompareMap2" class="px-2 fs-regular fw-500"
				>{{ $t('legend.legend') }} {{ $t('menu.map') }} 1</span
			>
			<span v-if="isActiveTabCompareMaps && this.isLegendCompareMap2" class="px-2 fs-regular fw-500"
				>{{ $t('legend.legend') }} {{ $t('menu.map') }} 2</span
			> -->
			<span class="px-2 fs-regular fw-500">{{ $t('legend.legend') }}</span>

			<v-btn
				height="24"
				width="24"
				depressed
				text
				icon
				:dark="expanded"
				class="map-legend__toggle-legend-btn ml-auto"
				:class="{ 'map-legend__toggle-legend-btn--selected': expanded }"
			>
				<v-icon size="12" class="color-black fw-light" :class="{ rotated: expanded }"
					>fak {{ expanded ? 'fa-line-less-zoom' : 'fa-line-more-zoom' }}</v-icon
				>
			</v-btn>
		</div>

		<div>
			<v-divider />
			<slot />
		</div>
	</v-card>
</template>

<script>
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'MapLegendControl',
	props: {
		isLegendCompareMap2: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			expanded: false
		};
	},

	computed: {
		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		},
		activeRasterLayer() {
			return this.$store.getters.getActiveRasterLayer;
		},
		activeCompareMap1RasterLayer() {
			return this.$store.getters.getActiveCompareMap1RasterLayer;
		},
		activeCompareMap2RasterLayer() {
			return this.$store.getters.getActiveCompareMap2RasterLayer;
		}
	},
	watch: {
		expanded(expanded) {
			if (!this.isLegendCompareMap2) {
				this.$store.commit('setMapLegendExpanded', expanded);
			} else {
				this.$store.commit('setMapLegendExpandedCompareMap', expanded);
			}
		},
		activeRasterLayer(val) {
			console.log('active RasterLayer', val);
			if (!this.isLegendCompareMap2) this.expanded = !!val;
		},
		activeCompareMap1RasterLayer(val) {
			console.log('active CompareMap1RasterLayer', val);
			if (!this.isLegendCompareMap2) this.expanded = !!val;
		},
		activeCompareMap2RasterLayer(val) {
			console.log('active CompareMap2RasterLayer', val);
			if (this.isLegendCompareMap2) this.expanded = !!val;
		}
	},

	destroyed() {
		this.$store.commit('setMapLegendExpanded', false);
		this.$store.commit('setMapLegendExpandedCompareMap', false);
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.map-control {
	width: 250px;
	position: absolute;
	z-index: 1;
	float: right;
	top: 1rem;
	right: 1rem;
}
.map-legend {
	&__toggle-legend-btn {
		&--selected {
			& i {
				color: var(--gris-300);

				&.rotated {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__row-title {
		font-size: 12px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #000;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	&__row-element {
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--medium);
	}
}

@media screen and (max-width: 860px) {
	.map-legend {
		top: 4rem;
	}
}
</style>
